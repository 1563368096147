<script setup>
const copyrightYear = new Date().getFullYear();
</script>

<template>
    <footer class="footer footer-center p-10 text-base-content">
        <div class="grid grid-flow-col gap-4">
            <a
                href="https://www.reach.nz/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                class="link link-hover"
                >Privacy Policy</a
            >
        </div>
        <div>
            <p>&copy; {{ copyrightYear }} REACH. All Rights Reserved</p>
        </div>
    </footer>
</template>
