<script setup>
import { reactive, ref } from "vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import { useFunctions } from "../services/firebase";
import { showToast, toastStatus } from "../services/utils/toast";
import ProgressSpin from "../components/utils/ProgressSpin.vue";
import BaseView from "./BaseView.vue";
import TextInput from "../components/form/TextInput.vue";
import BackButton from "../components/buttons/BackButton.vue";

const state = reactive({
    isLoading: false,
});
const quoteId = ref("");
const { copyProdQuote } = useFunctions();

const submit = async () => {
    if (quoteId.value.trim() === "") return;

    try {
        state.isLoading = true;
        await copyProdQuote(quoteId.value);

        quoteId.value = "";
        showToast("Success", toastStatus.SUCCESS);
    } catch (e) {
        console.error(e);
        showToast(e, toastStatus.ERROR);
    } finally {
        state.isLoading = false;
    }
};
</script>

<template>
    <BaseView>
        <div class="flex flex-row justify-between">
            <div></div>
            <BackButton />
            <SectionHeader
                label="Copy Prod Sputnik quote to Dev"
                :show-divider="false"
                :center="true"
            />
            <div></div>
            <div></div>
        </div>

        <div class="container mx-auto text-center">
            <p class="mb-1">
                This utility copies a quote from Sputnik Prod to Sputnik Dev.
            </p>
            <TextInput
                id="quoteId"
                v-model="quoteId"
                @keydown.enter="submit"
                placeholder="Enter quote ID"
                :autofocus="true"
                :required="true"
                class="w-3/4 sm:w-1/2 justify-self-center text-center"
            />
            <div class="mt-4">
                <ProgressSpin v-if="state.isLoading" />
                <UtilityButton v-else label="Copy Quote" :onClick="submit" />
            </div>
        </div>
    </BaseView>
</template>
