<script setup>
const props = defineProps({
    id: { type: String, required: true },
    label: { type: String },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    modelValue: { type: String, required: true },
    autofocus: { type: Boolean },
    required: { type: Boolean },
    maxlength: { type: Number },
    oninput: { type: Function },
    onKeydown: { type: Function },
    class: { type: String },
});
</script>

<template>
    <div class="grid gap-1">
        <label :for="props.id" class="font-medium">{{ props.label }}</label>
        <input
            :id="props.id"
            :type="props.type"
            :value="modelValue"
            :placeholder="props.placeholder"
            :autofocus="props.autofocus"
            :required="props.required"
            :maxlength="props.maxlength"
            :oninput="props.oninput"
            @keydown="props.onKeydown"
            @input="$emit('update:modelValue', $event.target.value)"
            :class="`p-2 bg-gray-50 rounded border-2 border-gray-300 dark:bg-dark-grey hover:cursor-pointer focus:border-peppermint-tint-2 focus:outline-none ${
                props.class ?? ''
            }`"
        />
    </div>
</template>
