import { createRouter, createWebHistory } from "vue-router";
import { BASE_PATH, SIGN_IN_PATH } from "../const/route";
import { useAuth } from "../services/firebase";
import { routes } from "./routes";

const { isUserSignedIn } = useAuth();

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    strict: true,
});

router.beforeEach(async (to) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const signedIn = await isUserSignedIn();

    if (requiresAuth && !signedIn) {
        let loginpath = window.location.pathname;

        if (to.query.ids) {
            loginpath = `${loginpath}?ids=${to.query.ids}`;
        }

        return { name: SIGN_IN_PATH, query: { from: loginpath } };
    }
    if (to.name === SIGN_IN_PATH && signedIn) {
        if (to.query?.from) {
            return to.query.from;
        }
        return { name: BASE_PATH };
    }
});

export default router;
