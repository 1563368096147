import {
    BASE_PATH,
    COPY_QUOTE_PATH,
    NOT_FOUND_PATH,
    SIGN_IN_PATH,
    TEST_DT_LOGIN_PATH,
} from "../const/route";
import Home from "../views/HomeView.vue";
import CopyQuoteView from "../views/CopyQuoteView.vue";
import TestDtLoginTokenView from "../views/TestDtLoginTokenView.vue";
import NotFound from "../views/NotFoundView.vue";

export const routes = [
    {
        path: BASE_PATH,
        name: BASE_PATH,
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${COPY_QUOTE_PATH}`,
        name: COPY_QUOTE_PATH,
        component: CopyQuoteView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${TEST_DT_LOGIN_PATH}`,
        name: TEST_DT_LOGIN_PATH,
        component: TestDtLoginTokenView,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}:pathMatch(.*)`,
        name: NOT_FOUND_PATH,
        component: NotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${SIGN_IN_PATH}`,
        name: SIGN_IN_PATH,
        component: () => import("../views/SignIn.vue"),
    },
];
