<script setup>
const props = defineProps({
    label: { type: String, required: true },
    showDivider: { type: Boolean, required: false, default: true },
    center: { type: Boolean },
});
</script>

<template>
    <div>
        <div :class="`flex justify-${props.center ? 'center' : 'between'}`">
            <h3 class="font-semibold text-xl">{{ props.label }}</h3>
            <slot name="action"></slot>
        </div>

        <hr v-if="props.showDivider" class="mt-1" />
    </div>
</template>
