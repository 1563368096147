<script setup>
import ButtonBase from "./ButtonBase.vue";

const props = defineProps({
    label: { type: String, required: true },
    onClick: { type: Function, required: true },
    tooltip: { type: String },
    inverse: { type: Boolean },
    noBorder: { type: Boolean },
    danger: { type: Boolean },
});
</script>

<template>
    <ButtonBase
        :class="{
            'inverse-button': props.inverse,
            'peppermint-button': !props.inverse,
            'inverse-button-border': !props.noBorder,
            'danger-button': props.danger,
        }"
        :label="props.label"
        :tooltip="props.tooltip"
        :on-click="props.onClick"
    >
        <slot></slot>
    </ButtonBase>
</template>
