import {
    GoogleAuthProvider,
    getAuth,
    signInWithRedirect,
    signOut,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { reactive } from "vue";
import { AUTH_UNKNOWN, CUSTOM_OAUTH_PARAMETERS } from "../../const/auth";
import { SIGN_IN_PATH } from "../../const/route";
import firebaseApp from "./init";

const auth = getAuth(firebaseApp);
const authState = reactive({
    auth,
    authenticated: AUTH_UNKNOWN,
    loading: true,
});

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
            authState.loading = authState.loading
                ? !authState.loading
                : authState.loading;
            resolve(user);
        }, reject);
    });
};

const signIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters(CUSTOM_OAUTH_PARAMETERS);

    try {
        await signInWithRedirect(auth, provider);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const isUserSignedIn = async () => {
    try {
        return (await getCurrentUser()) != null;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const userSignOut = () => {
    signOut(auth)
        .then(() => {
            authState.authenticated = AUTH_UNKNOWN;
            window.location.assign(`/${SIGN_IN_PATH}`);
        })
        .catch((e) => console.error(e));
};

export const useAuth = () => {
    return {
        authState,
        getCurrentUser,
        signIn,
        isUserSignedIn,
        userSignOut,
    };
};

const functions = getFunctions(firebaseApp, "australia-southeast1");

const copyProdQuote = async (quoteId) => {
    const copyProdQuoteFunction = httpsCallable(
        functions,
        "devUtilGroup-copyProdQuote"
    );

    return copyProdQuoteFunction({ quoteId });
};

const testDtLoginToken = async (dtCode) => {
    const testDtLoginTokenFunction = httpsCallable(
        functions,
        "devUtilGroup-testDtLoginToken"
    );

    return testDtLoginTokenFunction({ dtCode });
};

export const useFunctions = () => {
    return {
        copyProdQuote,
        testDtLoginToken,
    };
};
