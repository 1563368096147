<script setup></script>
<template>
    <svg
        viewBox="0 0 1024 1024"
        class="fill-current stroke-current w-4 h-4 inline-block"
        xmlns="http://www.w3.org/2000/svg"
    >
        <slot></slot>
    </svg>
</template>
