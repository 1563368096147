<script setup>
import { reactive, ref } from "vue";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import TextInput from "../components/form/TextInput.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import ProgressSpin from "../components/utils/ProgressSpin.vue";
import { useFunctions } from "../services/firebase";
import { showToast, toastStatus } from "../services/utils/toast";
import BaseView from "./BaseView.vue";
import BackButton from "../components/buttons/BackButton.vue";

const state = reactive({
    isLoading: false,
});
const dtCode = ref("");
const result = ref("");
const { testDtLoginToken } = useFunctions();

const submit = async () => {
    const dt = dtCode.value.replace(/\D/g, "");
    if (dt.trim() === "") return;

    try {
        state.isLoading = true;
        result.value = ""; // reset result
        const dtCodeResult = await testDtLoginToken(`DT${dt}`);

        if (dtCodeResult) {
            result.value = dtCodeResult.data;
            dtCode.value = "";
        } else {
            showToast("Failed to retrieve token", toastStatus.ERROR);
        }
    } catch (e) {
        console.error(e);
        showToast(e, toastStatus.ERROR);
    } finally {
        state.isLoading = false;
    }
};
</script>

<template>
    <BaseView>
        <div class="flex flex-row justify-between">
            <div></div>
            <BackButton />
            <SectionHeader
                label="Test DT login token"
                :show-divider="false"
                :center="true"
            />
            <div></div>
            <div></div>
        </div>

        <div class="container mx-auto text-center">
            <p class="mb-1">
                This utility generates the ID token of a DT used by the
                REACHTEAM mobile app.
            </p>
            <TextInput
                id="dtCode"
                v-model="dtCode"
                type="number"
                placeholder='Enter DT code without "DT"'
                :autofocus="true"
                :required="true"
                :maxlength="6"
                @keydown.enter="submit"
                class="w-3/4 sm:w-1/2 justify-self-center text-center"
            />
            <div class="mt-4">
                <ProgressSpin v-if="state.isLoading" />
                <UtilityButton
                    v-else
                    label="Get Login Token"
                    :onClick="submit"
                />
            </div>

            <textarea
                v-model="result"
                readonly
                :rows="5"
                class="p-2 bg-gray-50 rounded border-2 border-gray-300 dark:bg-dark-grey hover:cursor-pointer focus:border-peppermint-tint-2 focus:outline-none w-3/4 md:w-1/2 mx-auto mt-8"
            ></textarea>
        </div>
    </BaseView>
</template>
