import { ref } from "vue";

const DROPDOWN = { OPEN: "OPEN", CLOSE: "CLOSE" };

let currentDropdown;

export const clickHandler = () => {
    if (currentDropdown) currentDropdown.closeDropdown();
};

export const useDropdown = () => {
    const dropdownState = ref(DROPDOWN.CLOSE);

    const closeDropdown = () => {
        dropdownState.value = DROPDOWN.CLOSE;
    };

    const isHidden = () => dropdownState.value === DROPDOWN.CLOSE;

    const toggleDropdown = () => {
        dropdownState.value =
            dropdownState.value === DROPDOWN.CLOSE
                ? DROPDOWN.OPEN
                : DROPDOWN.CLOSE;
    };

    const clickEventHandler = (e, dataKey) => {
        e.stopPropagation();
        toggleDropdown();
        if (dataKey === currentDropdown?.dataKey) return;
        clickHandler();
        currentDropdown = { dataKey, closeDropdown };
    };

    return [clickEventHandler, isHidden, closeDropdown];
};
