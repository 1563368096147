<script setup>
import { useRouter } from "vue-router";
import { BASE_PATH } from "../../const/route";

const router = useRouter();
</script>

<template>
    <button
        class="text-peppermint"
        @click="() => router.push({ name: BASE_PATH })"
    >
        <span>Back</span>
    </button>
</template>
